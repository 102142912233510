import { Organization, User } from "@prisma/client"
import { GetContact } from "~/common/interfaces/zohoInterfaces/GetContact"
import { organizationsService } from "./organizationsService"
import { usersService } from "./usersService"
import { v4 as uuidv4 } from "uuid"
import { ItemResponse, SingleItemResponse } from "~/common/interfaces/zohoInterfaces/Item"

class ZohoService {
  async getContact(contactId: string) {
    const data = await $fetch<GetContact>(`/api/zoho/contacts/${contactId}`, {
      method: "GET"
    })
    if (!data) {
      throw Error("The contact was not found")
    }
    return data
  }

  async getItemById(itemId: string) {
    const data = await $fetch<SingleItemResponse>(`/api/zoho/items/${itemId}`, {
      method: "GET"
    })
    if (!data) {
      throw Error("The item was not found")
    }
    return data
  }

  async getLastInvoice(zohoId: string) {
    const data = await $fetch(`/api/zoho/invoices/last`, {
      method: "GET",
      query: {
        zohoId: zohoId
      }
    })
    if (!data) {
      throw Error("The last invoice was not found")
    }
    return data
  }

  async searchItems(query: string) {
    const data = await $fetch<ItemResponse>(`/api/zoho/items/search`, {
      method: "GET",
      query: {
        searchString: query
      }
    })
    if (!data) {
      throw Error("The items were not found")
    }
    return data
  }

  async syncZohoContact(contact: GetContact, ownerId?: number) {
    const { data: taxInfoData } = await useFetch(`/api/tax/info/zoho-id/${contact.contact.tax_id}`)
    if (!taxInfoData.value) {
      console.error("TaxInfo not found")
    }
    const { data: taxExemptionData } = await useFetch(`/api/tax/exemption/zoho-id/${contact.contact.tax_id}`)
    if (!taxExemptionData.value) {
      console.error("TaxExemption not found")
    }
    const { data: currencyId } = await useFetch(`/api/currencies/zoho-id/${contact.contact.currency_id}`)
    if (!currencyId.value) {
      console.error("Currency not found")
    }

    const mainEmail = contact.contact.contact_persons.find((person) => person.is_primary_contact)?.email
    const organizationData: Partial<Organization> = {
      ...(ownerId && { ownerUserId: ownerId }),
      name: contact.contact.contact_name ?? "",
      zohoId: contact.contact.contact_id,
      companyNumber:
        contact.contact.cf_customer_number ??
        contact.contact.cf_customer_number_text ??
        contact.contact.cf_customer_number_text_unformatted ??
        null,
      billingEmail: mainEmail ?? "",
      billingPhone: contact.contact.billing_address.phone ?? null,
      billingAddress: contact.contact.billing_address.address ?? null,
      billingAddress2: contact.contact.billing_address.street2 ?? null,
      billingCity: contact.contact.billing_address.city ?? null,
      billingState: contact.contact.billing_address.state ?? null,
      billingZip: contact.contact.billing_address.zip ?? null,
      billingCountry: contact.contact.billing_address.country ?? null,
      shippingPhone: contact.contact.shipping_address.phone ?? null,
      shippingAddress: contact.contact.shipping_address.address ?? null,
      shippingAddress2: contact.contact.shipping_address.street2 ?? null,
      shippingCity: contact.contact.shipping_address.city ?? null,
      shippingState: contact.contact.shipping_address.state ?? null,
      shippingZip: contact.contact.shipping_address.zip ?? null,
      shippingCountry: contact.contact.shipping_address.country ?? null,
      isTaxExempt: contact.contact.is_taxable,
      taxInfoId: taxInfoData.value?.body.taxInfoId ?? null,
      taxExemptionId: taxExemptionData.value?.body.taxExemptionId ?? null,
      currencyId: currencyId.value?.body.currencyId ?? null
    }

    const organization = await organizationsService.create(organizationData)

    return organization
  }

  async createContactPersons(contactPersons: GetContact["contact"]["contact_persons"]) {
    const mainContactPerson = contactPersons.find((person) => person.is_primary_contact)
    const otherContactPersons = contactPersons.filter((person) => !person.is_primary_contact)
    if (mainContactPerson) {
      const mainUserRaw: Partial<User> = {
        email: mainContactPerson.email,
        password: uuidv4(),
        resetKey: uuidv4(),
        firstName: mainContactPerson.first_name ?? "",
        lastName: mainContactPerson.last_name ?? "",
        phone: mainContactPerson.phone ?? "",
        status: "pending",
        mfaEnabled: false
      }
      const mainUser = await usersService.create([mainUserRaw])

      const otherUsersRaw: Partial<User>[] = otherContactPersons.map((person) => ({
        email: person.email,
        password: uuidv4(),
        resetKey: uuidv4(),
        firstName: person.first_name ?? "",
        lastName: person.last_name ?? "",
        phone: person.phone ?? "",
        status: "pending",
        mfaEnabled: false
      }))
      const otherUsers = await usersService.create(otherUsersRaw)
      if (otherUsers.length !== otherContactPersons.length) {
        console.error("Not all users were created")
      }

      return { mainUser: mainUser[0], otherUsers }
    } else if (otherContactPersons.length) {
      // Create users from otherContactPersons and return first in array
      const mainUserRaw: Partial<User> = {
        email: otherContactPersons[0].email,
        password: uuidv4(),
        resetKey: uuidv4(),
        firstName: otherContactPersons[0].first_name ?? "",
        lastName: otherContactPersons[0].last_name ?? "",
        phone: otherContactPersons[0].phone ?? "",
        status: "pending",
        mfaEnabled: false
      }
      const mainUser = await usersService.create([mainUserRaw])

      const otherUsersRaw: Partial<User>[] = otherContactPersons
        .filter((person, index) => index !== 0)
        .map((person) => ({
          email: person.email,
          password: uuidv4(),
          resetKey: uuidv4(),
          firstName: person.first_name ?? "",
          lastName: person.last_name ?? "",
          phone: person.phone ?? "",
          status: "pending",
          mfaEnabled: false
        }))
      const otherUsers = await usersService.create(otherUsersRaw)
      if (otherUsers.length !== otherContactPersons.length - 1) {
        console.error("Not all users were created")
      }

      return { mainUser: mainUser[0], otherUsers }
    } else {
      console.error("No contact persons found")
    }
  }

  async syncZohoContactPersons(contactId: string, organizationId: number) {
    const data = await $fetch(`/api/zoho/contacts/sync-contact-persons`, {
      method: "POST",
      body: { organizationId, contactId }
    })
    if (!data) {
      throw Error("No persons created")
    }
    return data
  }
}

export const zohoService = new ZohoService()
